import ReactGA from 'react-ga';
import { rateCallTwo } from '../api';
import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { applicationError, clearApplicationError, setDeclinationMessages, setQuoteIsLoading } from './quote';
import { bindableQuoteReceived, fatalError } from './application';
import { ENVIRONMENT_URL, ERRORS } from '../constants';
import { addSource } from '../helpers/addSource';
import { getIsDropin } from '../selectors/routing';
import { selectPartnerAgencyExternalId, selectAgentProducerExternalId } from '../selectors';
import { Dispatch } from 'redux';
import { selectIsAgentMDA } from '../selectors/agent';

const handleRateCallTwo = (data: Application = {}) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(setQuoteIsLoading(true));
    const { application } = getState();
    const isUserMDA = selectIsAgentMDA(getState());

    const sourceData = addSource({ isDropin: getIsDropin(getState()), isMDA: isUserMDA });

    const producerExternalId = selectAgentProducerExternalId(getState());
    const agencyExternalId = selectPartnerAgencyExternalId(getState());
    const addProducerOrAgencyExternalId = isUserMDA ? { producerExternalId } : { agencyExternalId };

    const requestData = {
      ...application,
      ...data,
      ...sourceData,
      ...addProducerOrAgencyExternalId,
    };

    return rateCallTwo(requestData)
      .then((res) => {
        if (res.data?.underwritingInformation?.declinations?.length > 0) {
          // combine all declinations from each policy object into a single array
          const declinations = res.data.underwritingInformation.declinations.reduce((acc: string[], item: any) => {
            const declination = Array.isArray(item.declination) ? item.declination[0] : item.declination;
            return acc.concat(declination);
          }, []);

          dispatch(setDeclinationMessages(declinations));
          dispatch(fatalError(ERRORS.CAT_1.DECLINATION_ERROR));
        }

        dispatch(setQuoteIsLoading(false));

        if (!res.data.isSuccess) {
          dispatch(applicationError(res.data.errors));
        } else {
          dispatch(bindableQuoteReceived(res.data.quote));
          dispatch(clearApplicationError());

          try {
            const { partner } = getState();

            if (partner?.partnerToken && window.location.hostname.toLowerCase() === ENVIRONMENT_URL.PRODUCTION) {
              ReactGA.initialize('UA-127203748-3');
              ReactGA.event({
                category: 'Partner Quote',
                action: partner.partnerToken,
                label: partner.name,
                nonInteraction: true,
              });
            }
          } catch (error) {
            reportToSentry(error as any);
          }
        }
      })
      .catch((error) => {
        dispatch(setQuoteIsLoading(false));
        dispatch(fatalError(error));
        reportToSentry(error, getRequestContext({ requestData }));
      });
  };
};

export default handleRateCallTwo;
