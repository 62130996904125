import { compose } from '../helpers/functionalHelpers';
import locationsCheck from '../helpers/locationsCheck';
import { validateAddons } from '../helpers/validateAddons';
import checkIfAddOnsAreComplete from '../helpers/checkIfAddonsAreComplete';
import { convertNumberToString } from '../helpers/valueConversion';
import {
  selectAdditionalInsureds,
  selectAnnualPayroll,
  selectApplication,
  selectLegalBusinessName,
  selectCertificationsMaintained,
  selectCertificationsRequired,
  selectContactFirstName,
  selectContactLastName,
  selectContactPhone,
  selectFailFastZip,
  selectGLLimit,
  selectGrossAnnualSales,
  selectIndustryState,
  selectMailingAddressCity,
  selectMailingAddressState,
  selectMailingAddressStreet,
  selectMailingAddressZip,
  selectLocationStreet,
  selectLocationCity,
  selectLocationZip,
  selectLocationState,
  selectNumEmployees,
  selectPlDeductible,
  selectPlLimit,
  selectPolicyTypes,
  selectPreviousLosses,
  selectUserHasSelectedAdditionalInsureds,
  selectYearsOfPriorActs,
  selectYearsOfProfessionalExperience,
  selectEndorsements,
  selectContactEmailIsValid,
  selectHasSprinklers,
  selectLimitDamagePremRented,
  selectContractorToolsLimit,
  selectBusinessStartDate,
  selectBusinessAgeInMonths,
} from './application';
import { getAvailableEndorsements } from './availableEndorsements';
import { selectBOPRatingEngine } from './quote';
import { validatePhoneNumber } from '../helpers/inputValidation/validatePhoneNumber';
import { createSelector } from 'reselect';
import { pageOrder, PAGES } from '../constants';
import { selectDBAIsValid } from './index';
import { selectIsStateZipVerified } from './businessInfo';
import { isBusinessStartDateValid } from '../helpers/isBusinessStartDateValid';
import { isYearsOfPriorActsValid } from '../helpers/isYearsOfPriorActsValid';
// Page Completed
export const isIndustryComplete = (state: ReduxState) =>
  compose(Boolean, convertNumberToString, selectIndustryState)(state);
export const isBusinessInfoComplete = (state: ReduxState) => {
  const businessStartDate = selectBusinessStartDate(state);
  return (
    selectNumEmployees(state) &&
    (selectFailFastZip(state).length === 5 || selectFailFastZip(state).length === 10) &&
    Array.isArray(selectPreviousLosses(state)) &&
    selectLegalBusinessName(state) !== '' &&
    Boolean(selectLocationState(state)) &&
    selectDBAIsValid(state) &&
    selectIsStateZipVerified(state) &&
    businessStartDate &&
    isBusinessStartDateValid(new Date(businessStartDate)).isValid
  );
};

export const isPoliciesComplete = (state: ReduxState) => Boolean(selectPolicyTypes(state).length);
export const isFinanceBasicsComplete = (state: ReduxState) =>
  Boolean(selectGrossAnnualSales(state) && selectAnnualPayroll(state));

export const isLocationsComplete = (state: ReduxState) => {
  const locations = locationsCheck(selectApplication(state)) || [];
  if (!locations.length) return false;

  const applicationTypes = selectPolicyTypes(state);
  const locationStreet = selectLocationStreet(state);
  const locationCity = selectLocationCity(state);
  const locationState = selectLocationState(state);
  const locationZip = selectLocationZip(state);
  const hasSprinklers = selectHasSprinklers(state);
  const areLocationComplete = [locationStreet, locationCity, locationState, String(locationZip)].every(
    (value) => value && value.length > 1
  );
  const areBizLocComplete = locations.reduce((result: boolean, location: Location) => {
    if (!result) return result;

    const { locationType, buildingLimit } = location;
    const typesWithoutBuildingLimit = ['CommercialCondoLeased', 'Home', 'BuildingLeased', 'Kiosk', 'Other'];
    const hasBuildingLimit = typesWithoutBuildingLimit.includes(locationType) || buildingLimit != null;

    return Boolean(locationType && hasBuildingLimit && typeof hasSprinklers === 'boolean');
  }, true);

  if (applicationTypes.includes('BOP')) {
    return areBizLocComplete && areLocationComplete;
  } else {
    return areLocationComplete;
  }
};

export const isGLComplete = (state: ReduxState) => Boolean(selectGLLimit(state));

interface isPLCompleteOptions {
  ignoreDefaults: boolean;
}

export const isPLComplete = (state: ReduxState, options: isPLCompleteOptions = { ignoreDefaults: false }) => {
  const occurrenceLimit = selectPlLimit(state);
  const deductibleAmount = selectPlDeductible(state);
  const yearsOfPriorActs = selectYearsOfPriorActs(state);
  const certificationsRequired = selectCertificationsRequired(state);
  const certificationsMaintained = selectCertificationsMaintained(state);
  const yearsOfProfessionalExperience = selectYearsOfProfessionalExperience(state);
  const businessStartDate = selectBusinessStartDate(state);
  const businessAgeInMonths = selectBusinessAgeInMonths(state);
  const isOccurrenceLimitComplete = typeof occurrenceLimit !== 'undefined' && occurrenceLimit >= 0;
  const isDeductibleAmountComplete = typeof deductibleAmount !== 'undefined' && deductibleAmount >= 0;
  const areDefaultsComplete = options.ignoreDefaults || (isOccurrenceLimitComplete && isDeductibleAmountComplete);

  return (
    areDefaultsComplete &&
    typeof yearsOfPriorActs !== 'undefined' &&
    typeof businessStartDate !== 'undefined' &&
    typeof businessAgeInMonths !== 'undefined' &&
    isYearsOfPriorActsValid(yearsOfPriorActs, businessAgeInMonths) &&
    typeof certificationsRequired !== 'undefined' &&
    typeof certificationsMaintained !== 'undefined' &&
    typeof yearsOfProfessionalExperience !== 'undefined'
  );
};

export const isAdditionalInsuredsComplete = (state: ReduxState) => {
  const shouldShowAdditionalInsuredsForm = selectUserHasSelectedAdditionalInsureds(state);
  const additionalInsureds = selectAdditionalInsureds(state);

  return Boolean(
    (shouldShowAdditionalInsuredsForm && additionalInsureds.length) || shouldShowAdditionalInsuredsForm === false
  );
};

export const isContactInfoComplete = (state: ReduxState) => {
  const isContactEmailValid = selectContactEmailIsValid(state) !== false;
  const isContactPhoneValid = validatePhoneNumber(selectContactPhone(state));
  const contactFirstName = selectContactFirstName(state);
  const contactLastName = selectContactLastName(state);
  const mailingAddressStreet = selectMailingAddressStreet(state);
  const mailingAddressCity = selectMailingAddressCity(state);
  const mailingAddressState = selectMailingAddressState(state);
  const mailingAddressZip = selectMailingAddressZip(state);
  const mailingComplete = [mailingAddressStreet, mailingAddressCity, mailingAddressState, mailingAddressZip].every(
    (value) => value && value.length > 1
  );

  return Boolean(contactFirstName && contactLastName && isContactEmailValid && isContactPhoneValid && mailingComplete);
};

export const isAddOnsComplete = (state: ReduxState) => {
  const isBop2Rated = selectBOPRatingEngine(state) === 'BOP 2.0';
  const availableEndorsements = getAvailableEndorsements(state);
  const endorsementsState = selectEndorsements(state);
  const areAddonQuestionsAnswered = checkIfAddOnsAreComplete(availableEndorsements, endorsementsState);
  const areAddOnsComplete = isBop2Rated ? areAddonQuestionsAnswered : true;
  const areAddOnsValid = validateAddons(state);
  const limitDamagePremRented = selectLimitDamagePremRented(state);
  const contractorToolsLimit = selectContractorToolsLimit(state);

  // when a user opens the contractor tools add on, the default limit will be set to 5,000.  This needs to be overridden by selecting a sub option for the next button to be come enabled
  const hasContractorToolsLimitDefault = contractorToolsLimit && contractorToolsLimit === 5000;

  // the limitDamagePremRented add on comes with a base limit of 50k so anything added by the user is additional to this base limit
  // limitDamagePremRented is considered valid if the limit is greater than or equal to $50,000
  const isLimitDamagePremRentedValid =
    typeof limitDamagePremRented === 'number' ? limitDamagePremRented >= 50_000 : true;

  return areAddOnsComplete && areAddOnsValid && isLimitDamagePremRentedValid && !hasContractorToolsLimitDefault;
};

const shouldRenderPageLink = (
  applicationTypes: string[],
  ratingEngine: string,
  availableEndorsements: AvailableEndorsementsState
) => {
  const isBop2Rated = ratingEngine === 'BOP 2.0';
  const areEndorsementsAvailable = availableEndorsements != null;
  const noneSelected = applicationTypes.length === 0;
  const isBopSelected = applicationTypes.includes('BOP');
  const isGlSelected = applicationTypes.includes('GL');
  const isPlSelected = applicationTypes.includes('PL');
  const isBopOrGlSelected = isBopSelected || isGlSelected;
  const shouldRenderAddons = (isBopSelected ? isBop2Rated : true) && areEndorsementsAvailable;

  return pageOrder.filter((page: string) => {
    if (page === PAGES.LOCATIONS) {
      if (noneSelected) {
        return false;
      }
    } else if (page === PAGES.GENERAL_LIABILITY) {
      if (noneSelected || !isBopOrGlSelected) {
        return false;
      }
    } else if (page === PAGES.PROFESSIONAL_LIABILITY) {
      if (noneSelected || !isPlSelected) {
        return false;
      }
    } else if (page === PAGES.ADD_ONS) {
      if (!shouldRenderAddons) {
        return false;
      }
    }

    return true;
  });
};

export const selectAvailablePages = createSelector(
  selectPolicyTypes,
  selectBOPRatingEngine,
  getAvailableEndorsements,
  shouldRenderPageLink
);
